<template>
  <ag-modal-container
    title="Ajouter un type de relevés"
    width="800px"
    @close="back"
  >
    <recordType-form
      v-model="value"
      level-choice
      @validate="isValid = $event"
    />

    <template v-slot:footer>
      <v-spacer />
      <v-btn
        :loading="loading"
        :disabled="!isValid"
        color="accent"
        class="ml-5"
        @click="create"
        v-text="'Ajouter'"
      />
      <v-spacer />
    </template>
  </ag-modal-container>
</template>

<script>
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'

import AgModalContainer from 'src/components/common/AgModalContainer'
import RecordTypeForm from '../components/RecordTypeForm'

export default {
  name: 'RecordTypeCreateContainer',
  components: {
    AgModalContainer,
    RecordTypeForm,
  },
  data: () => ({
    isValid: false,
    loading: false,
    value: {
      name: '',
      description: '',
      type: '',
      level: '',
      min: '',
      max: '',
      mask: '',
      interval: false,
      sampling: false,
      part: false,
      partType: null,
      destroy: false,
    },
  }),
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    back () {
      this.$router.back()
    },
    async create () {
      this.loading = true
      try {
        await Repositories.recordType.post(this.value)
        this.showSuccessNotification(`Le type de relevé ${this.value.name} a bien été créé`)
        this.back()
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loading = false
      }
    },
  },
}
</script>
